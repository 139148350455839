import { useNavigate } from '@remix-run/react';
import React, { useCallback } from 'react';
import { useEffectOnce } from 'react-use';

import { type DtoOnboardingTask } from '@lp-lib/api-service-client/public';

import { useLiveAsyncCall } from '../../../hooks/useAsyncCall';
import { type Organization } from '../../../types';
import { type NavigateOptions } from '../../../utils/remix';
import { Loading } from '../../Loading';
import { OnboardingTaskUtils } from '../utils';

export interface OnboardingTaskDetailSharedProps {
  organization: Organization;
  task: DtoOnboardingTask;
  onNext: () => Promise<void>;
  markAsDone: () => Promise<void>;
  markAsClosed: () => Promise<void>;
  refreshTasks: () => Promise<DtoOnboardingTask[]>;
}

export function OnboardingTaskDetailLayout(props: {
  title: React.ReactNode;
  description: React.ReactNode;
  children?: React.ReactNode;
}): JSX.Element {
  const { title, description, children } = props;

  return (
    <div className='mx-auto mb-10 w-148 max-w-full min-h-full flex flex-col justify-center items-center'>
      <header className='text-3.5xl font-bold'>{title}</header>
      <div className='mt-7.5 text-xl font-medium text-center'>
        {description}
      </div>
      {children}
    </div>
  );
}

export function OnboardingTaskSkipButton(
  props: OnboardingTaskDetailSharedProps & { className?: string }
): JSX.Element {
  const { onNext, className } = props;

  const {
    call: handleSkip,
    state: {
      state: { isRunning },
    },
  } = useLiveAsyncCall(async () => {
    await onNext();
  });

  return (
    <button
      type='button'
      className={`btn text-base text-icon-gray underline 
        flex justify-center items-center ${className}`}
      onClick={() => handleSkip()}
      disabled={isRunning}
    >
      {isRunning && (
        <Loading containerClassName='mr-2' text='' imgClassName='w-6 h-6' />
      )}
      Save for later
    </button>
  );
}

export function OnboardingTaskCloseButton(
  props: OnboardingTaskDetailSharedProps & { className?: string; text?: string }
): JSX.Element {
  const { onNext, markAsClosed, className, text = 'No thanks' } = props;

  const {
    call: handleClose,
    state: {
      state: { isRunning },
    },
  } = useLiveAsyncCall(async () => {
    await markAsClosed();
    await onNext();
  });

  return (
    <button
      type='button'
      className={`btn text-base text-icon-gray underline 
        flex justify-center items-center ${className}`}
      onClick={() => handleClose()}
      disabled={isRunning}
    >
      {isRunning && (
        <Loading containerClassName='mr-2' text='' imgClassName='w-6 h-6' />
      )}
      {text}
    </button>
  );
}

export function useNavigateToNextTask() {
  const navigate = useNavigate();

  return useCallback(
    (
      tasks: DtoOnboardingTask[],
      current?: DtoOnboardingTask | null,
      options?: NavigateOptions
    ) => {
      const currentIndex = tasks.findIndex((t) => t.name === current?.name);
      const nextTask = tasks.find(
        (t, index) =>
          index > currentIndex && OnboardingTaskUtils.CanBeProcessed(t)
      );
      const target = nextTask ? OnboardingTaskUtils.GetLink(nextTask) : '/home';
      navigate(target, options);
    },
    [navigate]
  );
}

export function NavigateToNextTask(props: {
  tasks: DtoOnboardingTask[];
  current?: DtoOnboardingTask | null;
}) {
  const navigate = useNavigateToNextTask();

  useEffectOnce(() => {
    navigate(props.tasks, props.current);
  });

  return null;
}
