import { useMemo } from 'react';

import {
  type DtoOnboardingTask,
  EnumsOnboardingTaskName,
  EnumsOnboardingTaskOperation,
} from '@lp-lib/api-service-client/public';

import { apiService } from '../../../services/api-service';
import { type Organization } from '../../../types';
import { useSlackInstalledCallbackModal } from '../../Channel';
import { HomeNavigator } from '../../Header/VenueHeader';
import { OnboardingTaskUtils } from '../utils';
import { OnboardingTaskAddTeam } from './OnboardingTaskAddTeam';
import { OnboardingTaskConnectToSlack } from './OnboardingTaskConnectSlack';
import {
  OnboardingTaskBookLiveGame,
  OnboardingTaskScheduleOnDGame,
} from './OnboardingTaskGames';
import { OnboardingTaskNotifyTeam } from './OnboardingTaskNotifyTeam';
import {
  OnboardingTaskSetUpPrograms,
  OnboardingTaskSubmitKeyDates,
} from './OnboardingTaskPrograms';
import {
  type OnboardingTaskDetailSharedProps,
  useNavigateToNextTask,
} from './Shared';

function ProgressBar(props: { total: number; completed: number }) {
  const completedPercentage =
    props.total > 0 ? (100 * props.completed) / props.total : 0;
  return (
    <div className='w-full h-2 rounded-lg bg-lp-gray-003'>
      <div
        style={{
          width: `${completedPercentage.toFixed(2)}%`,
        }}
        className='h-full rounded-lg bg-gradient-to-tr from-primary-start to-primary-end'
      ></div>
    </div>
  );
}

function Header(props: { tasks: DtoOnboardingTask[] }) {
  const { total, completed } = useMemo(
    () => ({
      total: props.tasks.filter((t) => OnboardingTaskUtils.IsAssigned(t))
        .length,
      completed: props.tasks.filter((t) => OnboardingTaskUtils.IsDone(t))
        .length,
    }),
    [props.tasks]
  );

  return (
    <div className='relative w-full h-20 flex items-center justify-center'>
      <div className='w-200 mx-20'>
        <ProgressBar total={total} completed={completed} />
      </div>

      <div className='absolute left-5'>
        <HomeNavigator homeURL='/home' />
      </div>
    </div>
  );
}

function Dispatcher(props: OnboardingTaskDetailSharedProps) {
  const { task } = props;

  switch (task.name) {
    case EnumsOnboardingTaskName.OnboardingTaskNameScheduleCall:
      return null;
    case EnumsOnboardingTaskName.OnboardingTaskNameConnectToSlack:
      return <OnboardingTaskConnectToSlack {...props} />;
    case EnumsOnboardingTaskName.OnboardingTaskNameAddTeam:
      return <OnboardingTaskAddTeam {...props} />;
    case EnumsOnboardingTaskName.OnboardingTaskNameNotifyTeam:
      return <OnboardingTaskNotifyTeam {...props} />;
    case EnumsOnboardingTaskName.OnboardingTaskNameSetUpPrograms:
      return <OnboardingTaskSetUpPrograms {...props} />;
    case EnumsOnboardingTaskName.OnboardingTaskNameSubmitDates:
      return <OnboardingTaskSubmitKeyDates {...props} />;
    case EnumsOnboardingTaskName.OnboardingTaskNameBookLiveShow:
      return <OnboardingTaskBookLiveGame {...props} />;
    case EnumsOnboardingTaskName.OnboardingTaskNameScheduleOndGame:
      return <OnboardingTaskScheduleOnDGame {...props} />;
    default:
      return null;
  }
}

export function OnboardingTaskDetail(props: {
  organization: Organization;
  tasks: DtoOnboardingTask[];
  task: DtoOnboardingTask;
  refreshTasks: () => Promise<DtoOnboardingTask[]>;
}) {
  const { organization, tasks, task, refreshTasks } = props;

  // Ideally we should not register the modal here, the the handleNext will
  // navigate to the next task page without forwarding location state.
  useSlackInstalledCallbackModal();

  const navigateToNextTask = useNavigateToNextTask();

  const handleNext = async () => {
    const tasks = await refreshTasks();
    navigateToNextTask(tasks, task);
  };

  const markAsDone = async () => {
    await apiService.onboarding.operateTask(task.name, {
      orgId: organization.id,
      operation: EnumsOnboardingTaskOperation.OnboardingTaskOperationMarkDone,
    });
  };

  const markAsClosed = async () => {
    await apiService.onboarding.operateTask(task.name, {
      orgId: organization.id,
      operation: EnumsOnboardingTaskOperation.OnboardingTaskOperationMarkClosed,
    });
  };

  return (
    <div className='w-full h-full flex flex-col bg-game-library bg-w-full bg-no-repeat text-white'>
      <Header tasks={tasks} />

      <div className='w-full flex-1 overflow-auto scrollbar'>
        <Dispatcher
          organization={organization}
          task={task}
          refreshTasks={refreshTasks}
          onNext={handleNext}
          markAsDone={markAsDone}
          markAsClosed={markAsClosed}
        />
      </div>
    </div>
  );
}
