import { Link } from '@remix-run/react';
import { useEffectOnce } from 'react-use';
import useSWR from 'swr';

import { EnumsProgramType } from '@lp-lib/api-service-client/public';

import { apiService } from '../../../services/api-service';
import {
  CelebrationDatesTemplateDownload,
  CelebrationDatesUploader,
} from '../../Celebrations/CelebrationDatesImporter';
import { Loading } from '../../Loading';
import {
  ProgramsActivation,
  useProgramActivationData,
} from '../../Program/ProgramsActivation';
import { ProgramUtils } from '../../Program/utils';
import {
  OnboardingTaskDetailLayout,
  type OnboardingTaskDetailSharedProps,
  OnboardingTaskSkipButton,
} from './Shared';

function OnboardingTaskSetUpProgramsCompleted(
  props: OnboardingTaskDetailSharedProps
) {
  useEffectOnce(() => {
    props.onNext();
  });
  return <Loading />;
}

export function OnboardingTaskSetUpPrograms(
  props: OnboardingTaskDetailSharedProps
): JSX.Element {
  const { organization } = props;

  const { data, isLoading } = useProgramActivationData(props.organization.id);
  if (isLoading || !data) return <Loading />;

  const initSelectedPrograms = data.uninstalledPrograms.filter((p) =>
    [
      EnumsProgramType.ProgramTypeWaterCooler,
      EnumsProgramType.ProgramTypeBirthdayAndCelebrations,
    ].includes(p.type)
  );
  return (
    <OnboardingTaskDetailLayout title={''} description={''}>
      <ProgramsActivation
        orgId={organization.id}
        programs={data.uninstalledPrograms}
        initSelectedPrograms={initSelectedPrograms}
        onSkip={() => props.onNext()}
        renderCompleted={() => (
          <OnboardingTaskSetUpProgramsCompleted {...props} />
        )}
      />
    </OnboardingTaskDetailLayout>
  );
}

async function loadLatestCelebrationData(orgId: string) {
  const channels = (await apiService.channel.queryChannels(orgId)).data
    .channels;
  const link = channels
    .flatMap((channel) => channel.programLinks)
    .filter((link) => link.programType === 'birthday_and_celebrations')
    .reduce((prev, current) =>
      prev.createdAt < current.createdAt ? current : prev
    );
  if (!link) return null;
  const [celebration, participants] = await Promise.all([
    (
      await apiService.celebration.getCelebration(link.programTargetId)
    ).data.celebration,
    (
      await apiService.celebration.getParticipants(link.programTargetId)
    ).data.participants,
  ]);

  return { link, celebration, participants };
}

export function OnboardingTaskSubmitKeyDates(
  props: OnboardingTaskDetailSharedProps
): JSX.Element | null {
  const { organization, markAsDone, onNext } = props;

  const { data, isLoading, mutate } = useSWR(
    ['onboarding/tasks/submit-dates', organization.id],
    () => loadLatestCelebrationData(organization.id)
  );

  const handleUpload = async (success: boolean) => {
    mutate();
    if (success) {
      await markAsDone();
      await onNext();
    }
  };

  if (isLoading) return <Loading />;
  if (!data) return null;

  return (
    <OnboardingTaskDetailLayout
      title='Submit Dates'
      description='In order to celebrate your team’s birthdays and anniversaries, we’ll need to know some key dates.'
    >
      <div className='mt-14 flex flex-col gap-8 text-left'>
        <div>
          <p className='text-sms'>Step 1: Download the template</p>
          <p className='text-sms text-icon-gray'>
            Choose date format for the .csv file.
          </p>
          <div className='mt-3'>
            <CelebrationDatesTemplateDownload
              participants={data?.participants ?? []}
            />
          </div>
        </div>
        <div>
          <p className='text-sms'>
            Step 2: Fill in your team’s birthdays and work start dates
          </p>
          <p className='text-sms text-icon-gray'>
            Do not change the columns or format, and export as a .csv file.
          </p>
        </div>
        <div>
          <p className='text-sms'>Step 3: Upload your filled out sheet</p>
          <p className='text-sms text-icon-gray'>
            Upload the .csv file with all the dates you want to import. <br />
            (Only .csv file format accepted)
          </p>
        </div>
      </div>
      <div className='mt-15 w-full flex justify-center gap-5'>
        <Link
          to={`${ProgramUtils.BuildURL(data.link, 'participants')}`}
          className='btn-secondary w-75 h-15 flex justify-center items-center'
        >
          Add dates manually
        </Link>
        <CelebrationDatesUploader
          celebration={data.celebration}
          onUpdate={handleUpload}
          className='w-75 h-15'
        />
      </div>
      <OnboardingTaskSkipButton {...props} className='mt-4' />
    </OnboardingTaskDetailLayout>
  );
}
