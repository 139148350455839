import { useState } from 'react';

import { EnumsExConnectType } from '@lp-lib/api-service-client/public';
import { type Media } from '@lp-lib/media';

import { useLiveAsyncCall } from '../../../hooks/useAsyncCall';
import { apiService } from '../../../services/api-service';
import { type SlackChannel } from '../../../types/slack';
import { err2s } from '../../../utils/common';
import { MediaUtils } from '../../../utils/media';
import {
  SlackMessageInput,
  SlackMessagePreview,
} from '../../Channel/Slack/SlackMessage';
import { CopyButton } from '../../common/CopyButton';
import { Loading } from '../../Loading';
import { LUNA_PARK_INTRO_MEDIA } from '../../MediaUploader/consts';
import { useMyOrgConnection } from '../../Organization';
import { SlackChannelPicker } from '../../Slack';
import {
  OnboardingTaskCloseButton,
  OnboardingTaskDetailLayout,
  type OnboardingTaskDetailSharedProps,
  OnboardingTaskSkipButton,
} from './Shared';

const OnboardingAnnouncement = {
  slack: {
    text: `🎉 I'm excited to share that we've partnered with Luna Park to bring you unlimited experiences. 

You can jump into the Luna Park library any time and access hundreds of fun social games - escape rooms, icebreakers, trivia, and even customized Jeopardy! It’s a great way to connect 🎡

Click <${window.location.origin}|here> to check it out!
`,
    media: LUNA_PARK_INTRO_MEDIA,
  },
  email: {
    subject: '🎉 We’ve partnered with Luna Park!',
    body: `Hi Team,  

🎉 I'm excited to share that we've partnered with Luna Park to bring you unlimited experiences.

You can jump into the Luna Park library any time and access hundreds of fun social games - from arcade games, trivia games, wordplay games, to fun icebreakers! It’s a great way to connect 🎡

Click here to check it out: ${window.location.origin}`,
  },
};

export function OnboardingTaskNotifyTeamWithSlack(
  props: OnboardingTaskDetailSharedProps
): JSX.Element {
  const { organization, onNext, markAsDone } = props;

  const [text, setText] = useState(OnboardingAnnouncement.slack.text);
  const [media, setMedia] = useState<Media | null>(
    OnboardingAnnouncement.slack.media
  );
  const [channel, setChannel] = useState<SlackChannel | null>(null);

  const {
    call: handleSend,
    state: {
      error: sendErr,
      state: { isRunning: isSending },
    },
  } = useLiveAsyncCall(async () => {
    if (!channel) return;

    const mediaList = [];
    if (media) {
      const messageMedia = MediaUtils.CovertToMessageMedia(media, {
        title: 'Luna Park intro.mp4',
      });
      if (messageMedia) mediaList.push(messageMedia);
    }

    await apiService.message.shareMessage({
      exGroupId: channel.id,
      text,
      mediaList,
    });
    await markAsDone();
    await onNext();
  });

  return (
    <OnboardingTaskDetailLayout
      title='Notify Team'
      description='Share the news with the team and tell them a bit about what Luna Park has to offer!'
    >
      <div className='mt-7.5 w-full'>
        <SlackMessageInput
          text={text}
          onTextChange={(v) => setText(v)}
          media={media}
          onMediaChange={(v) => setMedia(v)}
          onMediaReset={() => setMedia(LUNA_PARK_INTRO_MEDIA)}
        />
      </div>
      <div className='mt-5 w-full text-left'>
        Here's how your message will appear
      </div>
      <div className='mt-4 w-full'>
        <SlackMessagePreview
          text={text}
          media={media}
          className='h-100 overflow-auto scrollbar'
        />
      </div>

      <div className='mt-5 w-full text-left'>Share to</div>
      <div className='mt-1.5 w-full'>
        <div className='w-75'>
          <SlackChannelPicker
            orgId={organization.id}
            onChange={setChannel}
            types={'public'}
          />
        </div>
      </div>

      <footer className='mt-7.5'>
        {sendErr && (
          <div className='text-sms text-red-002'>{err2s(sendErr)}</div>
        )}
        <button
          type='button'
          disabled={!channel || !text || isSending}
          className='btn-primary w-100 h-15 flex justify-center items-center'
          onClick={() => handleSend()}
        >
          {isSending && (
            <Loading containerClassName='mr-2' text='' imgClassName='w-6 h-6' />
          )}
          Send
        </button>
        <div className='mt-4 flex justify-center items-center gap-7.5'>
          <OnboardingTaskSkipButton {...props} />
          <OnboardingTaskCloseButton
            {...props}
            text='I already notified my team'
          />
        </div>
      </footer>
    </OnboardingTaskDetailLayout>
  );
}

export function OnboardingTaskNotifyTeamWithoutSlack(
  props: OnboardingTaskDetailSharedProps
): JSX.Element {
  const { onNext, markAsDone } = props;

  const {
    call: handleDone,
    state: {
      state: { isRunning: isMutatingDone },
    },
  } = useLiveAsyncCall(async () => {
    await markAsDone();
    await onNext();
  });

  return (
    <OnboardingTaskDetailLayout
      title='Notify Team'
      description='Share the news with the team and tell them a bit about what Luna Park has to offer!'
    >
      <CopyButton
        className='mt-10 btn flex items-center text-primary underline text-xl'
        copiedText={OnboardingAnnouncement.email.body}
      >
        Copy Announcement Text
      </CopyButton>

      <p className='mt-4 text-center max-w-100'>
        You can paste this in an email or message to your team to announce Luna
        Park.
      </p>

      <footer className='mt-5'>
        <button
          type='button'
          onClick={() => handleDone()}
          className='btn-primary w-100 h-15 flex justify-center items-center'
          disabled={isMutatingDone}
        >
          {isMutatingDone && <Loading containerClassName='mr-2' text='' />}
          Done
        </button>

        <div className='mt-4 flex items-center justify-center gap-7.5'>
          <OnboardingTaskSkipButton {...props} />

          <OnboardingTaskCloseButton
            {...props}
            text='I already notified my team'
          />
        </div>
      </footer>
    </OnboardingTaskDetailLayout>
  );
}

export function OnboardingTaskNotifyTeam(
  props: OnboardingTaskDetailSharedProps
) {
  const connection = useMyOrgConnection();
  const slackInstalled =
    !!connection &&
    connection.type === EnumsExConnectType.ExConnectTypeSlack &&
    connection.status === 'active';
  if (slackInstalled) return <OnboardingTaskNotifyTeamWithSlack {...props} />;
  return <OnboardingTaskNotifyTeamWithoutSlack {...props} />;
}
