import { type ClientLoaderFunctionArgs, useLoaderData } from '@remix-run/react';
import { useMemo } from 'react';

import { GlobalLoading } from '../components/GlobalLoading';
import {
  NavigateToNextTask,
  OnboardingTaskDetail,
  useOnboardingTasks,
} from '../components/Onboarding';
import { useMyOrganization } from '../components/Organization';
import { err2s } from '../utils/common';

export const clientLoader = async (action: ClientLoaderFunctionArgs) => {
  const { name } = action.params;

  return {
    name,
  };
};

export function Component() {
  const { name } = useLoaderData<typeof clientLoader>();

  const organization = useMyOrganization();
  const {
    data: tasks,
    mutate,
    isLoading,
    error,
  } = useOnboardingTasks(organization?.id);
  const task = useMemo(
    () => tasks?.find((t) => t.name === name),
    [name, tasks]
  );

  if (!organization) return <GlobalLoading />;
  if (error) return <div>{err2s(error)}</div>;
  if (isLoading) return <GlobalLoading />;

  if (!task || !tasks) {
    return <NavigateToNextTask tasks={tasks || []} />;
  }
  return (
    <OnboardingTaskDetail
      organization={organization}
      tasks={tasks}
      task={task}
      refreshTasks={async () => {
        return (await mutate()) || [];
      }}
    />
  );
}
