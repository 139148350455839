import { useTriggerOrganizationDoNotUseSlackModal } from '../../Organization';
import { SlackInstallButton } from '../../Slack';
import {
  OnboardingTaskDetailLayout,
  type OnboardingTaskDetailSharedProps,
  OnboardingTaskSkipButton,
} from './Shared';

export function OnboardingTaskConnectToSlack(
  props: OnboardingTaskDetailSharedProps
) {
  const { organization, markAsDone, onNext } = props;

  const triggerDoNotUseSlackModal = useTriggerOrganizationDoNotUseSlackModal();

  const handleClickDoNotUseSlack = () => {
    triggerDoNotUseSlackModal({
      organization: organization,
      onConfirmed: () => {
        markAsDone();
        onNext();
      },
    });
  };

  return (
    <OnboardingTaskDetailLayout
      title='Add to Slack'
      description='Luna Park works best with Slack. Connect to unlock additional features, get notifications, and manage team access'
    >
      <div className='mt-15'>
        <SlackInstallButton
          text='Add to Slack'
          from={'/onboarding/tasks/latest'}
          className='btn-primary w-100 h-15'
        />
      </div>
      <div className='mt-4 flex justify-center items-center gap-7.5'>
        <OnboardingTaskSkipButton {...props} />
        <button
          type='button'
          className='btn text-base text-icon-gray underline'
          onClick={handleClickDoNotUseSlack}
        >
          We don't use Slack
        </button>
      </div>
    </OnboardingTaskDetailLayout>
  );
}
